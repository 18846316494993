<template>
  <HeadlessCombobox v-model="filter.stage" multiple>
    <AuctionButtonsContainer>
      <AuctionListFilterButton as="button" @click="toggleAll">
        {{ $t('auction-list.all') }}
      </AuctionListFilterButton>
      <AuctionListFilterButton
        v-for="stage in filterData.stages"
        :key="stage"
        :selected="filter.stage.includes(stage)"
        :value="stage"
      >
        {{ $t(`auction-list.${stage.replace(/_/g, '-').toLowerCase()}`) }}
      </AuctionListFilterButton>
    </AuctionButtonsContainer>
  </HeadlessCombobox>
</template>

<script setup lang="ts">
import type { Ref } from 'vue'
import { storeToRefs } from 'pinia'
import { useAuctionStore } from '@autobid/nuxt-pinia-store/store/useAuctionStore'
import type { ParsedFilterData } from '@autobid/strapi-integration/typescript/FilterData'
import AuctionListFilterButton from './AuctionListFilterButton.vue'
import AuctionButtonsContainer from './AuctionButtonsContainer.vue'

const auctionStoreKey = inject('auctionStoreKey')
const { filter } = storeToRefs(useAuctionStore(auctionStoreKey))

const filterData = inject('filterData') as Ref<ParsedFilterData>

const toggleAll = () => {
  filter.value.stage =
    filter.value.stage.length === filterData.value.stages.length
      ? []
      : filterData.value.stages
}
</script>
